import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import Spinner from 'react-bootstrap/Spinner';
import _ from 'lodash';

import api from '../util/API';

const LOCAL_STORAGE_KEY = "CHART_HIDE_SERIES-";


function MyChart({ storageKey, source, type, stacked, yFormatter, xLabelsFormat, tooltipXFormat, title, debounce, startTime, endTime, options }) {
  const [total, setTotal] = useState(null);
  const [data, setData] = useState(null);
  const [displayData, setDisplayData] = useState(null);
  const [hiddenSeries, setHiddenSeries] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY + storageKey)) || {});
  const [seriesByName, setSeriesByName] = useState({});


  useEffect(() => {
    // sync to local storage on every change
    if (storageKey) {
      localStorage.setItem(LOCAL_STORAGE_KEY + storageKey, JSON.stringify(hiddenSeries));
    }
  }, [hiddenSeries, storageKey]);

  useEffect(() => {
    if (data) {
      // filter out hidden series
      setDisplayData(data.filter(e => !hiddenSeries[e.room_id]));
      // only sum series that are not hidden
      const t = data.reduce((partialSum, e) => partialSum + (hiddenSeries[e.room_id] === true ? 0 : e.total), 0);
      setTotal(t);
    }
    else {
      setDisplayData(null);
      setTotal(0);
    }
  }, [data, hiddenSeries]);

  useEffect(() => {
    var current = true; // prevent delayed display of old requests
    const loadData = async () => {
      setData(null);
      setTotal(null);
      const res = await api.get(source);
      if (current) {
        setData(res.data);
        setSeriesByName(Object.fromEntries(res.data.map(e => [e.name, e.room_name || e.name])))
      }
    };

    if (data === false || !debounce) {
      loadData(); // don't debounce for the first time
    }
    else {
      // debounce successive calls
      const timeout = setTimeout(loadData, debounce);
      // this will cancel the previous call if still scheduled
      return () => {
        clearTimeout(timeout);
        current = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const chartOptions = _.merge({
    chart: {
      // this will force ApexCharts to update the config - this is for the legendClick function that needs to be up to date and have the current reference to displayData
      _uncache: (displayData || []).map(e => e.room_id),
      group: 'charts',
      background: '#fff',
      animations: {
        enabled: false,
      },
      stacked: stacked,
      toolbar: {
        autoSelected: '', // prevents selection-zooming while scrolling on touch device
      },
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          const roomId = displayData[seriesIndex].room_id;
          hiddenSeries[roomId] = true;
          setHiddenSeries({...hiddenSeries});
        },
      },
    },
    colors: [
      '#e6194B', '#3cb44b', '#808000', '#4363d8', '#f58231',
      '#911eb4', '#42d4f4', '#f032e6', '#469990', '#9A6324',
      '#000075', '#a9a9a9', '#000000', '#800000',
      '#aaffc3', '#ffd8b1', '#ffe119', '#bfef45', '#fabed4', '#dcbeff'
    ],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      min: startTime,
      max: endTime,
      labels: {
        datetimeUTC: false,
        format: xLabelsFormat,
      },
      lines: {
        show: true,
      },
      tooltip: {
        shared: true,
        enabled: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
      labels: {
        formatter: yFormatter,
      },
      forceNiceScale: true,
    },
    zoom: {
      enabled: true,
      autoScaleYaxis: true,
    },
    stroke: {
      show: true,
      curve: 'straight', // smooth was too slow, but nicer
      lineCap: 'butt',
      width: 2,
    },
    tooltip: {
      x: {
        format: tooltipXFormat,
      },
      y: {
        title: {
          formatter: (seriesName) => seriesByName[seriesName],
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      onItemClick: {
        toggleDataSeries: false
      },
    },
  }, options)

  return (
    <div>
      <h4 className="bg-light mb-0 pb-0 pt-1 mt-1 text-center">{title(total)}</h4>
      {displayData ? <>
        <div style={{height: "350px"}}>
          <Chart
            height={"100%"}
            options={chartOptions}
            series={displayData}
            type={type}
          />
        </div>
        <div className="bg-white d-flex justify-content-center mb-4">
          {(data || []).filter(e => hiddenSeries[e.room_id]).map(e => (
            <div key={e.room_id} className={"apexcharts-legend-series" + hiddenSeries[e.room_id] ? " apexcharts-inactive-legend" : ""} style={{margin: "2px 5px"}}
              onClick={() => {
                delete hiddenSeries[e.room_id];
                setHiddenSeries({...hiddenSeries});
              }}
            >
              <span className="apexcharts-legend-marker" style={{background: e.color, color: e.color, height: "12px", width: "12px", left: "0px", top: "0px", borderWidth: 0, borderColor: "rgb(255, 255, 255)", borderRadius: "12px"}}></span>
              <span className="apexcharts-legend-text" style={{color: "rgb(55, 61, 63)", fontSize: "12px", fontWeight: 400, fontFamily: "Helvetica, Arial, sans-serif"}}>
                {e.name}
              </span>
            </div>
          ))}
        </div>
        </>
      :
        <Spinner animation="border" variant="primary"/>
      }
    </div>
  );
}

export default MyChart;
