
if (window.location.hash === "#fullscreen") {
  localStorage.setItem('pwa_fullscreen', true);
}

let fullscreen = localStorage.getItem('pwa_fullscreen');
const manifest = fullscreen ? '/manifest-fs.json' : '/manifest.json';

let element = document.createElement('link');
element.setAttribute('rel', 'manifest');
element.setAttribute('href', process.env.PUBLIC_URL + manifest);
document.querySelector('head').appendChild(element);
