import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import Accordion from 'react-bootstrap/Accordion';
import { FiHome as HomeIcon } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from "react-router-dom";
import {useAuth} from '../util/AuthProvider';

import './Menu.css';
import api from '../util/API';


function MyMenuItem(props) {
  const navigate = useNavigate();

  if (props.action) {
    return (
      <Accordion.Item eventKey={props.eventKey} className="bm-item"
        onClick={(evt) => {
          evt.stopPropagation();
          props.closeMenu();
          navigate(props.action);
        }}>
        <Accordion.Header className="leaf">
          {props.label}
        </Accordion.Header>
      </Accordion.Item>
    );
  }
  else if (props.href) {
    return (
      <Accordion.Item eventKey={props.eventKey} className="bm-item">
        <Accordion.Header className="leaf">
          <a href={props.href} target="_blank" rel="noreferrer">{props.label}</a>
        </Accordion.Header>
      </Accordion.Item>
    );
  }
  else {
    return (
      <Accordion.Item eventKey={props.eventKey} className="bm-item">
        <Accordion.Header>
          {props.label}
        </Accordion.Header>
        {props.items &&
          <Accordion.Body>
            <MyMenuItemList items={props.items} closeMenu={props.closeMenu}/>
          </Accordion.Body>
        }
      </Accordion.Item>
    );
  }
}

function MyMenuItemList(props) {
  return (
    <Accordion flush={true} >
      {props.items.map((e, i) => (
        <MyMenuItem key={i} eventKey={i} {...e} closeMenu={props.closeMenu}/>
      ))}
    </Accordion>
  );
}

function MyHomeIcon() {
  const location = useLocation();
  if (location.pathname !== '/') {
    return (
      <Link to="/">
        <HomeIcon
          color='orange'
          size={36}
          className="homeIcon"
        />
      </Link>
    )
  }
  else {
    return <div className="homeIconPadding"></div>;
  }
}

class MyMenu extends React.Component {
  // save menu in LS in case the request fails in the future
  LOCAL_STORAGE_KEY = 'main_menu';

  constructor() {
    super();
    this.state = {
      menu: JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY)) || [],
      menuOpen: false,
    };
  }

  async componentDidMount() {
    try {
      const menu = await api.get("menu");
      this.setState({menu: menu.menu});
      localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(menu.menu));
    } catch (err) {
      console.log("Can't download menu. Using cached ones.");
    }
  }

  render() {
    return (
      <>
        <Menu
          isOpen={this.state.menuOpen}
          onOpen={() => this.setState({menuOpen: true})}
          onClose={() => this.setState({menuOpen: false})}
        >
          <MyMenuItemList
            items={this.state.menu}
            closeMenu={() => this.setState({menuOpen: false})}
          />
          <span className="fixed-bottom text-dark ms-3 version">Version: {process.env.REACT_APP_VERSION}</span>
        </Menu>
        <MyHomeIcon/>
      </>
    );
  }
}

function AuthProtectedMenu() {
  const {user} = useAuth();
  return user ? <MyMenu/> : null;
}

export default AuthProtectedMenu;
