import React, { useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import api from '../util/API';


function APIPost({endpoint, paramName}) {
  const navigate = useNavigate();
  const params = useParams();
  const paramValue = params[paramName];

  useEffect(() => {
    const doIt = async () => {
      try {
        var url = paramValue ? `${endpoint}/${paramValue}` : endpoint;
        await api.post(url);
      }
      finally {
        navigate('/', {replace: true});
      }
    }
    doIt();
  }, [endpoint, navigate, paramValue]);

  return <Spinner animation="border" variant="primary"/>;
}

export default APIPost;
