import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

import { FaPlusCircle as Plus, FaMinusCircle as Minus } from 'react-icons/fa';

import api from '../util/API';
import {useMQTTData} from '../util/MQTTDataProvider';


function TempChangeModal({room, show, onClose}) {
  const [temperature, setTemperature] = useState(0);
  const [startTime, setStartTime] = useState(moment().format().substring(0, 16));
  const [endTime, setEndTime] = useState(moment().format().substring(0, 16));

  const {roomId, tweaks, temperature_target: currentTargetTemperature} = room || {};

  const maxTemp = tweaks?.targetMax || tweaks?.rangeMax || 25;
  const step = tweaks?.step || 1;
  const unit = tweaks?.unit || "°C";

  useEffect(() => {
    if (show) { // run every time the dialog opens
      (async () => {
        const data = await api.get(`tempChange/${roomId}`);
        setStartTime(moment().format().substring(0, 16));
        setEndTime(data.datetime || moment().format().substring(0, 16));
        setTemperature(data.temperature || parseFloat(localStorage.getItem("temporaryChange_temperature_"+roomId)) || currentTargetTemperature || 0);
      })();
    }
  }, [show, currentTargetTemperature, roomId]);

  const onConfirm = async () => {
    const data = {startTime, endTime, temperature};
    localStorage.setItem("temporaryChange_temperature_"+roomId, temperature);
    await api.post(`tempChange/${roomId}`, data);
    onClose();
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Temporarily Adjust {room?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Row className="mb-3">
          <Col sm={3}>
            <Form.Label className="mt-4">{tweaks?.label || "Heat to"}</Form.Label>
          </Col>
          <Col sm={9} className="text-center">
            <Minus color="green" size={64} className="cursorPointer float-start"
              onClick={(evt) => {
                evt.stopPropagation();
                setTemperature(Math.max(0, temperature - step));
              }}
            />
            <span style={{whiteSpace: 'nowrap'}} className="d-inline-block mt-2">
              <Form.Control type="number" min="0" max={maxTemp}
                className="d-inline-block"
                size="lg"
                step={step}
                value={temperature}
                onChange={evt => {
                  setTemperature(Math.min(Math.max(parseFloat(evt.target.value), 0), maxTemp));
                }}
              />{unit}
            </span>
            <Plus color="green" size={64} className="cursorPointer float-end"
              onClick={(evt) => {
                evt.stopPropagation();
                setTemperature(Math.min(maxTemp, temperature + step));
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={3}>
            <Form.Label className="mt-4">from</Form.Label>
          </Col>
          <Col sm={9}>
            <Form.Control type="datetime-local" step="1800"
              size="lg"
              value={startTime}
              onChange={evt => {
                setStartTime(evt.target.value)
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={3}>
            <Form.Label className="mt-4">until</Form.Label>
          </Col>
          <Col sm={9}>
            <Form.Control type="datetime-local" step="1800"
              size="lg"
              value={endTime}
              onChange={evt => {
                setEndTime(evt.target.value)
              }}
            />
          </Col>
        </Row>

      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button size="lg" variant="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

/**
  Opens a temporary change modal based on the #tempChangeModal=X in URL
*/
function TempChangeModalLocationHash() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { rooms } = useMQTTData();

  const match = hash.match(/tempChangeModal=([0-9]+)/);
  if (!match) {
    return null;
  }

  const roomId = parseInt(match[1]);
  const room = rooms[roomId]
  if (!room) {
    return null; // wait for room data to load and it will come back here
  }
  return (
    <TempChangeModal
      room={room}
      show={true}
      onClose={() => navigate('#')}/>
  )
}

export default TempChangeModal;
export {TempChangeModalLocationHash};
