import React, {useState, useEffect} from 'react';
import { FaPersonThroughWindow as WindowIcon } from "react-icons/fa6";

import './Room.css';
import RoomContent, { getColor } from './RoomContent';
import {useMQTTData} from '../util/MQTTDataProvider';


function calculateAggregates(room, aggregates, allRooms) {
  if (!room || !allRooms || !aggregates) {
    return room;
  }
  // create a copy so we're not changing fields in the main shared record
  room = {...room};

  aggregates?.fieldsSum.forEach(f => {
    var val = 0;
    aggregates.roomIds.forEach(rId => {
      val += allRooms[rId] ? allRooms[rId][f] : 0;
    });
    room[f] = val;
  });
  return room;
}

function Room({layoutItem, room}) {
  const [uncache, setUncache] = useState(new Date().getTime());
  const {rooms: allRooms} = useMQTTData();

  useEffect(() => {
    if (layoutItem.img) {
      // reload selected images (weather forecast) at regular intervals (30min)
      const interval = setInterval(() => setUncache(new Date().getTime()), 30*60*1000);
      return () => clearInterval(interval);
    }
  }, [layoutItem.img]);

  // CustomElement is for things like clock/tea timer, etc.
  var CustomElement = null;
  if (layoutItem.element) {
    CustomElement = require(`./${layoutItem.element}`).default;
  }

  var backgroundStyle = {};
  if (room) {
    backgroundStyle = {'background': getColor(room)};
  }
  if (layoutItem.roomIds) {
    const colors = layoutItem.roomIds.map(e => getColor(allRooms[e]));
    backgroundStyle = {'background': `linear-gradient(${colors.join(', ')})`};
  }

  room = calculateAggregates(room, layoutItem.aggregates, allRooms);

  return (
    <div className="grid-stack-item"
      gs-x={layoutItem.x} gs-y={layoutItem.y}
      gs-w={layoutItem.width} gs-h={layoutItem.height}
    >
      <div className={`grid-stack-item-content d-flex flex-column ${layoutItem.cssClass || ''}`}
        style={backgroundStyle}
        data-room-id={layoutItem.roomId}
      >
        {room?.error ?
          <div>{room.error}</div>
        :
          <>
            {layoutItem.element ?
              <CustomElement layoutItem={layoutItem} room={room}/>
            :
              <>
                {layoutItem.roomName &&
                  <div className="room-name">
                  {layoutItem.roomName}
                  {(room?.contact === false) &&
                    <WindowIcon
                      color="orange"
                      size={20}
                      className="windowIcon"
                    />
                  }
                  </div>
                }

                {layoutItem.roomId && <RoomContent layoutItem={layoutItem} room={room}/>}

                {layoutItem.roomIds &&
                  <div className="d-flex flex-column justify-content-between flexFillHeight">
                  {layoutItem.roomIds.map(roomId => (
                    <React.Fragment key={roomId}>
                      {!layoutItem.roomName && // we either have one "room" name or we display the individual ones
                        <div className="room-name">
                          {allRooms[roomId]?.name}
                        </div>
                      }
                      <RoomContent layoutItem={layoutItem} room={allRooms[roomId]} background={{}}/>
                    </React.Fragment>
                  ))}
                  </div>
                }

                {layoutItem.img &&
                  <img src={`${layoutItem.img}${layoutItem.img.includes("?") ? "&" : "?"}uncache=${uncache}`} alt="" style={{width: "100%"}}/>
                }
              </>
            }
          </>
        }
      </div>
    </div>
  )
}

export default Room;
