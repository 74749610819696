import React, {useState} from 'react';
import { FiActivity as HeatIcon } from 'react-icons/fi';
import { FiArrowRight as TrendIcon } from 'react-icons/fi';

import TempChangeModal from './TempChangeModal';


export function getColor(room) {
  if (!room) {
    return null;
  }
  if (room.error) {
    return 'hsl(300, 50%, 40%)';
  }
  else if (room.temperature == null) {
    return 'hsl(300, 10%, 40%)';
  }
  else if (room.temperature !== undefined) {
    let min = room?.tweaks?.rangeMin || 10;
    let max = room?.tweaks?.rangeMax || 25;

    // calculate HSV colour for the background based on temperature.
    // <= 10C is blue, >=25 is red
    let h = 240 - ((room.temperature - min) / (max-min) * 240);
    h = Math.max(h, 0);
    h = Math.min(h, 240);

    return 'hsl('+h+', 50%, 40%)';
  }
}

export default function RoomContent({room, layoutItem, background}) {
  const [showTempChange, setShowTempChange] = useState(false);

  const heating_effort = room?.heating_effort || 0;
  const effortStyle = {
    width: `${heating_effort}%`,
    backgroundColor: `hsl(${65 - (heating_effort*0.65)}, 100%, 50%)`,
  };

  return (
    <>
      {room?.has_heater &&
        <TempChangeModal
          room={room}
          show={showTempChange}
          onClose={() => setShowTempChange(false)}/>
      }
      <div
        className="room-content position-relative flexFillHeight"
        style={background || {'background': getColor(room)}}
        onClick={() => setShowTempChange(true)}
      >
        <div className="zoomed flexFillHeight" style={layoutItem.zoom ? {transform: `scale(${layoutItem.zoom}%)`} : null}>
          <>
            {!isNaN(room?.temperature) &&
              <>
                <div className="room-temperature">
                  {room.temperature.toFixed(1)}
                </div>
                <div className="room-temperature_trend">
                  {(room?.temperature_trend_rotation != null) && <TrendIcon
                    color='silver'
                    size={24}
                    style={{'transform': 'rotate('+room?.temperature_trend_rotation+'deg)'}}
                  />}
                </div>
              </>
            }
            {!isNaN(room?.humidity) &&
              <div className="room-humidity">{Math.round(room.humidity)}</div>
            }
            {!isNaN(room?.power_watts) &&
              <div className="room-power">
                {(room?.power_watts>0) && (room.power_watts / 1000).toFixed(1)}
              </div>
            }
          </>
        </div>
        {!isNaN(room?.temperature_target) && ((room.temperature_target > 0) || room?.temporary_change) &&
          <div className={"room-temperature_target" + (room?.temporary_change ? " temporary-change-active" : "")}>
            {room?.temperature_target}
          </div>
        }
        {((room?.power_output > 0) || (room?.power_watts > 0) || (room?.pwm > 0)) &&
          <div className="room-power-icon">
            <HeatIcon
              color="orange"
              size={28}
              style={{'opacity': 0.5 + ((room?.pwm || 100)/200.0) }}
              title={room?.pwm ? `${room.pwm}%` : null}
            />
          </div>
        }
        {(room?.heating_effort > 0) && <div className="room-effort" style={effortStyle}/>}
      </div>
    </>
  );
}
