import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from '../util/API';

function RoomsModal({sensorId, rooms, onClose}) {
  const navigate = useNavigate();

  const save = async (roomId, sensorId) => {
    await api.post(`unmappedSensors`, {
      roomId: roomId,
      sensorId: sensorId,
    });
    navigate('/');
  };

  return (
    <Modal show={!!sensorId} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{sensorId}: Assign Room</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rooms.map(room => (
          <Button key={room.roomId}
            variant="success"
            className="d-block mb-3 w-100"
            onClick={() => {save(room.roomId, sensorId)}}
          >
            {room.name}
          </Button>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>

  );
}


function UnmappedSensors(props) {
  const [sensors, setSensors] = useState({});
  const [rooms, setRooms] = useState([]);
  const [sensorId, setSensorId] = useState(null);

  useEffect(() => {
    (async()=>{
      const res = await api.get(`unmappedSensors`);
      setSensors(res.sensors);
      setRooms(res.rooms);
    })();
  }, []);

  return (
    <Form>
      <RoomsModal
        rooms={rooms}
        sensorId={sensorId}
        onClose={() => setSensorId(null)}
      />

      {Object.keys(sensors).map(k => (
        <Row key={k} className="bg-secondary mb-3">
          <Col>
            <Button
              size="lg"
              variant="primary"
              onClick={() => setSensorId(k)}
            >
              {k}
            </Button>
          </Col>
          <Col>
            <span className="text-light">
              {Object.keys(sensors[k]).map((field) => (
                <React.Fragment key={field}>
                  {field === 'device' ?
                    `model: ${sensors[k]['device']['model']}`
                  :
                    `${field}: ${JSON.stringify(sensors[k][field])}`
                  }
                  <br/>
                </React.Fragment>
              ))}
            </span>
          </Col>
        </Row>
      ))}
    </Form>
  );
}

export default UnmappedSensors;
