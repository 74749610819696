import { createContext, useContext, useEffect, useMemo } from "react";
import moment from 'moment';

import { useLocalStorage } from "./useLocalStorage";
import api from './API';

const AuthContext = createContext();

class CurrentUser {
  tokens = null;
  setTokens(tokens) {
    this.tokens = tokens;
    this.details = tokens ? decodeToken(tokens.access) : null;
  }
}
const currentUser = new CurrentUser();
export {currentUser};

function decodeToken(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("authenticated_user", null);

  if (!currentUser.tokens) {
    currentUser.setTokens(user);
  }

  // call this function when you want to authenticate the user
  const login = (data) => {
    setUser({
      ...data,
      refreshed: moment().format(),
    });
    currentUser.setTokens(data);
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    currentUser.setTokens(null);
  };

  useEffect(() => {
    if (user) {
      // check if token is expired
      const expiry = decodeToken(user.access).exp;
      if (expiry < new Date().getTime()/1000) {
        // force login
        logout();
      }
      else {
        const refreshToken = async () => {
          // don't refresh too often, there's no need to. Especialy don't refresh right after login.
          if (moment(user.refreshed) < moment().subtract(24, 'hours')) {
            const newToken = await api.post('auth/token/refresh/', {refresh: user.refresh});
            if (newToken && newToken.access && newToken.refresh) {
              login(newToken);
            }
          }
        }

        // refresh token on app load
        refreshToken();

        // and then try to refresh the token every 12 hours
        const interval = setInterval(refreshToken, 1000 * 60 * 60 * 12);
        return () => clearInterval(interval);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]); // can't include the login/logout function, it would be a deadlock

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user] // ignore exhaustive-deps, because including login+logout would result in a deadlock
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export {AuthContext};
export const useAuth = () => {
  return useContext(AuthContext);
};
