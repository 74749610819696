import { createContext, useContext, useMemo, useState } from "react";
import moment from 'moment';
import Paho from 'paho-mqtt';

import {useAuth} from './AuthProvider';
import {currentUser} from './AuthProvider';


const MQTT_HOST = process.env.REACT_APP_MQTT_HOST;
const MQTT_PORT = parseInt(process.env.REACT_APP_MQTT_PORT);
const MQTT_SSL = MQTT_PORT === 443;


const MQTTContext = createContext();

class MQTTClient {
  state = {plugins: {}, rooms: {}};
  lastActiveMessage = moment();

  constructor(onUpdate) {
    this.onUpdate = onUpdate;
    this.mqttConnect();
    this.watchDogInterval = setInterval(this.watchDog.bind(this), 1000);
  }

  watchDog() {
    if (this.lastActiveMessage < moment().subtract(60, 'seconds')) {
      this.setState('error', "Error fetching data from server.");
      this.setState('plugins', {});
      this.setState('rooms', {});

      // connect new mqtt client
      this.mqttConnect();

      // give the client a chance to connect before we end up here again.
      this.lastActiveMessage = moment();
    }
  }

  setState(key, value) {
    this.state[key] = value;
    // propagate data to the context provider
    this.onUpdate({...this.state});
  }

  messageHandlers = [
    {match: /smartheat\/active/i, handle: (matches, payload) => {
      console.log(payload)
      this.lastActiveMessage = moment(payload);
      if (this.lastActiveMessage < moment().subtract(30, 'seconds')) {
        // Don't reconnect immediately, this means we're connected to MQTT, just the server is down.
        this.lastActiveMessage = moment();
      }
      else if (this.state.error) {
        this.setState('error', null);
      }
    }},
    {match: /smartheat\/room\/(\d+)/i, handle: (matches, payload) => {
      const roomId = matches[1];
      this.state.rooms[roomId] = payload;
      this.setState('rooms', this.state.rooms);
    }},
    {match: /smartheat\/currentProfile/i, handle: (matches, payload) => {
      this.setState('currentProfile', payload);
    }},
    {match: /smartheat\/plugin\/(\w+)/i, handle: (matches, payload) => {
      const plugin = matches[1];
      this.state.plugins[plugin] = payload;
      this.setState('plugins', this.state.plugins);
    }},
    {match: /smartheat\/icons/i, handle: (matches, payload) => {
      this.setState('icons', payload);
    }},
  ];

  mqttDataReceived(msg) {
    this.messageHandlers.forEach(handler => {
      const matches = msg.topic.match(handler.match);
      if (matches) {
        //console.log(matches, JSON.parse(msg.payloadString));
        handler.handle(matches, JSON.parse(msg.payloadString));
      }
    });
  }

  onMqttConnectionLost(err) {
    if (err?.errorCode === 0)
      return; // this is weirdly called on successful connection, too

    console.log("MQTT connection lost.", err);
    this.setState('error', "Connection to server lost. Reconnecting...");
    // re-connect in 5 seconds
    this.lastActiveMessage = moment().subtract(55, 'seconds');
  }

  mqttConnect() {
    try {
      this.mqttClient?.disconnect();
    } catch (e) {
      console.log("Trouble disconnecting mqtt.", e);
    } finally {
      this.mqttClient = null;
    }

    console.log("Connecting new MQTT client.")

    this.mqttClient = new Paho.Client(MQTT_HOST, MQTT_PORT, "Frontend" + new Date().getTime());
    this.mqttClient.onMessageArrived = this.mqttDataReceived.bind(this);
    this.mqttClient.onConnectionLost = this.onMqttConnectionLost.bind(this);
    this.mqttClient.connect({
      userName: currentUser.details.sub,
      password: currentUser.tokens.access,
      useSSL: MQTT_SSL,
      reconnect: false,
      onSuccess: () => {
        this.mqttClient.subscribe("smartheat/#", {qos: 1});
      },
      onFailure: this.onMqttConnectionLost.bind(this),
    });
  }
}


const MQTTDataProvider = ({ children }) => {
  const [data, setData] = useState({plugins: {}, rooms: {}});
  const { user } = useAuth();
  useMemo(() => user ? new MQTTClient(setData) : null, [setData, user]);

  return <MQTTContext.Provider value={data}>{children}</MQTTContext.Provider>;
};

export {MQTTDataProvider, MQTTContext};

export const useMQTTData = () => {
  return useContext(MQTTContext);
};
