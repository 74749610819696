import React, {useState, useEffect, useMemo} from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';
import { useSearchParams } from "react-router-dom";

import api from '../util/API';
import './Dashboard.css';
import DashboardLayout from './DashboardLayout';
import {TempChangeModalLocationHash} from './TempChangeModal';
import InfoBar from './InfoBar';


/** changes the slide to the one requested in layoutId URL param */
function MySwiperController() {
  const [searchParams, setSearchParams] = useSearchParams();
  const layoutId = searchParams.get('layoutId');
  const swiper = useSwiper();

  if (layoutId) {
    setTimeout(() => {
      swiper.slideTo(parseInt(layoutId));
      setSearchParams({});
    }, 0);
  }
  return null;
}

function Dashboard() {
  // save layout in LS in case the request fails in the future
  const LOCAL_STORAGE_KEY = 'dashboard_layouts';
  const LOCAL_STORAGE_KEY_CURRENT_LAYOUT = 'dashboard_layouts_current';

  const [layouts, setLayouts] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || []);
  const layoutId = useMemo(() => JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CURRENT_LAYOUT)), []);

  useEffect(() => {
    (async function() {
      try {
        const layouts = await api.get("layouts");
        setLayouts(layouts.layouts);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(layouts.layouts));
      } catch (err) {
        console.log("Can't download layouts. Using cached ones.", err);
      }
    })();
  }, []);

  if (layouts) {
    return (
      <>
        <InfoBar/>

        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          initialSlide={layoutId !==null ? layoutId : undefined}
          //loop
          keyboard={{
            enabled: true,
            onlyInViewport: false,
          }}
          cssMode={true}
          onSlideChange={(s) => localStorage.setItem(LOCAL_STORAGE_KEY_CURRENT_LAYOUT, s.activeIndex)}
        >

          <MySwiperController/>

          {layouts.map((l, i) => (
            <SwiperSlide key={i}>
              <DashboardLayout id={i} {...l}/>
            </SwiperSlide>
          ))}

        </Swiper>
        <TempChangeModalLocationHash/>
      </>
    );
  }
}

export default Dashboard;
