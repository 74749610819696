import React, {useState} from 'react';
import _ from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './BufferTank.css';

import RoomContent, { getColor } from './RoomContent';
import TempChangeModal from './TempChangeModal';
import {useMQTTData} from '../util/MQTTDataProvider';

function HeatingElement({room}) {
  const [showTempChange, setShowTempChange] = useState(false);
  if (!room) {
    return null;
  }

  if ((isNaN(room?.temperature_target) || !room.temperature_target) && !room?.temporary_change) {
    return null;
  }

  return (
    <>
      <TempChangeModal
        room={room}
        show={showTempChange}
        onClose={() => setShowTempChange(false)}/>

      <div
        className="heatingElement align-self-end"
        onClick={() => setShowTempChange(true)}
      >
        <div className="room-name">
          {room?.name}
        </div>
        <div>
          <div className="flexFillHeight d-flex">
            {!isNaN(room?.temperature) &&
              <>
                <div className="room-temperature">
                  {room.temperature.toFixed(1)}
                </div>
              </>
            }
            <div className={"room-temperature_target" + (room?.temporary_change ? " temporary-change-active" : "")}>
              {room?.temperature_target}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function BufferTank({layoutItem, room})  {
  const {rooms: allRooms} = useMQTTData();

  const roomMapping = layoutItem.roomMapping;
  if (!roomMapping || _.isEmpty(allRooms)) {
    return null;
  }

  const totalPower =
    allRooms[roomMapping.element1]?.power_watts +
    allRooms[roomMapping.element2]?.power_watts +
    allRooms[roomMapping.element3]?.power_watts;
  const totalEffort = Math.max(
    allRooms[roomMapping.element1]?.heating_effort,
    allRooms[roomMapping.element2]?.heating_effort,
    allRooms[roomMapping.element3]?.heating_effort);
  const effortStyle = {
    width: `${totalEffort}%`,
    backgroundColor: `hsl(${65 - (totalEffort*0.65)}, 100%, 50%)`,
  };

  return (
    <>
      <div className="room-name">
        {layoutItem.roomName}
      </div>
      <div className="bufferTank flexFillHeight d-flex flex-column justify-content-between flexFillHeight">
        <RoomContent
          layoutItem={{}}
          room={allRooms[roomMapping.temperatureTop]}
          background={{}}
        />
        <div className="d-flex justify-content-end flexFillHeight">
          <HeatingElement room={allRooms[roomMapping.element1]}/>
          <HeatingElement room={allRooms[roomMapping.element2]}/>
        </div>
        <RoomContent
          layoutItem={{}}
          room={allRooms[roomMapping.temperatureMiddle]}
          background={{}}
        />
        <HeatingElement room={allRooms[roomMapping.element3]}/>
        <RoomContent
          layoutItem={{}}
          room={allRooms[roomMapping.temperatureLower]}
          background={{}}
        />

        <div className="align-self-end flexFillHeight">
          <div className="room-power">
            {(totalPower>0) && (totalPower / 1000).toFixed(1)}
          </div>
        </div>
      </div>
      <div className="room-effort" style={effortStyle}/>
    </>
  );
}

export default BufferTank;
