import {useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import {useAuth} from '../util/AuthProvider';


function Logout() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.logout();
    navigate('/login');
  });
}

export default Logout;
