import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from '../util/API';
import {useAuth} from '../util/AuthProvider';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  const auth = useAuth();
  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      if (username && password) {
        const user = await api.post('auth/token/', {username, password});
        auth.login(user);
        navigate('/');
      }
    } catch (err) {
      setError("Nope! Probably just a typo. Try better.");
    }
  }

  return (
    <Form className="text-center" style={{maxWidth: 500}} onSubmit={login}>
      <Row className="mt-5 mb-2">
        <Col>
          <Form.Label as="h4" className="text-light">Username</Form.Label>
        </Col>
        <Col>
          <Form.Control type="text"
            size="lg"
            value={username}
            autoCapitalize="off"
            onChange={evt => {
              setUsername(evt.target.value);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Label as="h4" className="text-light">Password</Form.Label>
        </Col>
        <Col>
          <Form.Control type="password"
            size="lg"
            value={password}
            onChange={evt => {
              setPassword(evt.target.value);
            }}
          />
        </Col>
      </Row>
      {error && <Row>
        <Col>
          <h4 className="text-danger">{error}</h4>
        </Col>
      </Row>}
      <Row>
        <Col>
          <Button type="submit" variant="success" size="lg">Login</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default Login;
