import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import './App.css';

import Menu from './components/Menu';
import APIPost from './components/APIPost';
import AuthProvider, {useAuth} from './util/AuthProvider';

import Login from './components/Login';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';
import DayStatistics from './components/DayStatistics';
import YearStatistics from './components/YearStatistics';
import ProfileConfig from './components/ProfileConfig';
import UnmappedSensors from './components/UnmappedSensors';
import {MQTTDataProvider} from './util/MQTTDataProvider';


function Protected({children}) {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
}

function App() {
  return (
    <AuthProvider>
      <MQTTDataProvider>
        <BrowserRouter>
          <Menu/>
          <Routes>
            <Route path="/" element={<Protected><Dashboard /></Protected>} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/statistics/day" element={<Protected><DayStatistics /></Protected>} />
            <Route path="/statistics/year" element={<Protected><YearStatistics /></Protected>} />
            <Route path="/config/profile/:profileId" element={<Protected><ProfileConfig/></Protected>} />
            <Route path="/setProfile/:profileId" element={<Protected><APIPost endpoint="profile/set" paramName="profileId"/></Protected>} />
            <Route path="/config/unmappedSensors" element={<Protected><UnmappedSensors/></Protected>} />
            <Route path="/tempChange/cancel/:roomId" element={<Protected><APIPost endpoint="tempChange/cancel" paramName="roomId" /></Protected>} />
            <Route path="/triggerAction/:index" element={<Protected><APIPost endpoint="triggerAction" paramName="index"/></Protected>} />
          </Routes>
        </BrowserRouter>
      </MQTTDataProvider>
    </AuthProvider>
  );
}

export default App;
