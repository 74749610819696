import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { BsFan as PumpIcon } from "react-icons/bs";

import api from '../util/API';
import SetProfileModal from './SetProfileModal';
import {useMQTTData} from '../util/MQTTDataProvider';


function InfoBar() {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const {currentProfile, error, plugins, icons} = useMQTTData();

  return (
    <>
      {icons?.heating_pump && <PumpIcon color="orange" size="36" className="pump me-2 my-2"/>}
      {error && <div className="infoTextDiv">{error}</div>}
      <SetProfileModal
        show={showProfileModal}
        size="lg"
        onClose={() => setShowProfileModal(false)}
      />
      {currentProfile &&
        <div id="currentProfile" className="infoTextDiv"
          onClick={() => setShowProfileModal(true)}
        >
          {currentProfile}
        </div>
      }
      {Object.values(plugins).map((pluginEntry) => pluginEntry.map((e, i) => (
        <React.Fragment key={i}>
          {e.action &&
            <Link to={e.action}>
              <div className="infoTextDiv">{e.text}</div>
            </Link>
          }

          {e.api &&
            <div className={"infoTextDiv cursorPointer " + (e.cssClass || "")}
              onClick={async (evt) => {
                evt.stopPropagation();
                await api[e.api_method || 'post'](e.api);
              }}
            >
              {e.text}
            </div>
          }

          {!e.action && !e.api && <div className={"infoTextDiv " + (e.cssClass || "")}>{e.text}</div>}
        </React.Fragment>
      )))}
    </>
  );
}

export default InfoBar;
