import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaPlusCircle as Plus, FaMinusCircle as Minus } from 'react-icons/fa';
import moment from 'moment';

import './Clock.css';
import waitImg from '../media/waiting.gif';
import doneImg from '../media/pupup.jpg';
import alarmSound from '../media/cililink.mp3';


class TeaTimer extends React.Component {
  state = {countdown: 0};

  start() {
    this.setState({countdown: 3 * 60});

    this.interval = setInterval(() =>
      this.setState({countdown: this.state.countdown - 1})
    , 1000);
  }

  close() {
    clearInterval(this.interval);
    this.props.onClose();
  }

  renderCountdown() {
    const countdown = this.state.countdown;
    return (
      <div className="d-flex justify-content-between">
        <Minus color="green" size={128} className="cursorPointer float-start"
          onClick={(evt) => {
            evt.stopPropagation();
            if (this.state.countdown > 60) {
              this.setState({countdown: this.state.countdown - 60})
            }
          }}
        />
        <img src={waitImg} alt="Flippy"/>
        <span className="time">
          {Math.floor(countdown / 60)}
          :
          {String(countdown % 60).padStart(2, '0')}
        </span>
        <Plus color="green" size={128} className="cursorPointer float-end"
          onClick={(evt) => {
            evt.stopPropagation();
            if (this.state.countdown < 9 * 60) {
              this.setState({countdown: this.state.countdown + 60})
            }
          }}
        />
      </div>
    );
  }

  renderDone() {
    return (
      <div className="d-flex justify-content-around" onClick={this.close.bind(this)}>
        <img src={doneImg} height={160} alt="Pup-up"/>
        <span className="time">Tea is ready!</span>
        <audio src={alarmSound} autoPlay loop/>
      </div>
    );
  }

  render() {

    return (
      <Modal
        show={this.props.show}
        onHide={this.close.bind(this)}
        onShow={this.start.bind(this)}
        dialogClassName="tea-timer-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Tea Timer</Modal.Title>
        </Modal.Header>
        <Modal.Body id="timer-dialog">
          {this.state.countdown > 0 ?
            this.renderCountdown()
          :
            this.renderDone()
          }
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button size="lg" variant="secondary" onClick={this.close.bind(this)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function Clock({layoutItem})  {
  const [currentTime, setCurrentTime] = useState(moment());
  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(moment()), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <TeaTimer
        show={showTimer}
        onClose={(evt) => setShowTimer(false)}
      />

      <div
        style={{overflow: 'hidden', height: '100%'}}
        onClick={() => setShowTimer(true)}
      >
        <div className="room-name">Clock</div>
        <div className="datetime zoomed flexFillHeight">
          <div className="date">
            {currentTime.format("dddd")}<br/>
            {currentTime.format("D/M/YYYY")}
          </div>
          <div className="time">
            {currentTime.format("HH:mm")}
          </div>
        </div>
      </div>
    </>
  );
}

export default Clock;
