import axios from 'axios';

import {currentUser} from './AuthProvider';


const API_BASE = process.env.REACT_APP_API_URL;

class API {
  getAxios() {
    if (currentUser.tokens) {
      return axios.create({
        headers: {
          Authorization : `Bearer ${currentUser.tokens.access}`
        }
      });
    }
    else {
      return axios;
    }
  }

  async get(endpoint) {
    const response = await this.getAxios().get(`${API_BASE}${endpoint}`);
    return response.data;
  }

  async post(endpoint, data={}) {
    const response = await this.getAxios().post(`${API_BASE}${endpoint}`, data);
    return response.data;
  }

  async delete(endpoint) {
    const response = await this.getAxios().delete(`${API_BASE}${endpoint}`);
    return response.data;
  }

}

export default new API();
