import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TiFlowSwitch as ExchangeIcon, TiFlowParallel as BypassIcon } from 'react-icons/ti';
import { BsHouse as HouseIcon } from 'react-icons/bs';
import { BsArrowRightShort as RightIcon, BsArrowLeftShort as LeftIcon } from "react-icons/bs";

import './Ventilation.css';
import RoomContent, { getColor } from './RoomContent';
import TempChangeModal from './TempChangeModal';


function Ventilation({layoutItem, room})  {
  const [showTempChange, setShowTempChange] = useState(false);
  const [tempChangeRoom, setTempChangeRoom] = useState(null);

  const bypass = room?.bypass?.output_state === "Active";

  const bgsvg = 'url("data:image/svg+xml;utf8,' + encodeURIComponent(`
    <svg preserveAspectRatio='none' viewBox='0 0 1 1' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient id="g">
          <stop offset="0" stop-color="#fff" stop-opacity="0"/>
          <stop offset="1" stop-color="#fff" stop-opacity="1"/>
        </linearGradient>
        <mask id="m">
          <rect x="0" y="0" width="1" height="1" fill="url(#g)"/>
        </mask>
        <linearGradient id="a" gradientTransform="rotate(90)">
          <stop offset="0" stop-color="${getColor(room?.houseIn)}"></stop>
          <stop offset="1" stop-color="${getColor(room?.houseOut)}"></stop>
        </linearGradient>
        <linearGradient id="b" gradientTransform="rotate(90)">
          <stop offset="0" stop-color="${getColor(bypass ? room?.freshIn : room?.exhaust)}"/>
          <stop offset="1" stop-color="${getColor(bypass ? room?.exhaust : room?.freshIn)}"></stop>
        </linearGradient>
      </defs>
      <rect x="0" y="0" width="1" height="1" fill="url(#a)" mask="url(#m)"></rect>
      <rect x="0" y="0" width="1" height="1" fill="url(#b)" mask="url(#m)" transform="translate(1,1) rotate(180)"></rect>
    </svg>`) + '")';
  const background = {backgroundImage: bgsvg};

  const ModeIcon = bypass ? BypassIcon : ExchangeIcon;

  return (
    <>
      <TempChangeModal
        room={tempChangeRoom}
        show={showTempChange}
        onClose={() => setShowTempChange(false)}/>

      <div style={background} className="flexFillHeight">
        <div className="room-name">
          {layoutItem.roomName}
        </div>
        <div className="zoomed flexFillHeight">
          <Container fluid>
            <Row className="gx-0 gx-lg-2">
              <Col
                className="flex-column justify-content-start d-none d-lg-flex" sm="auto"
              >
                {bypass ? <>
                  <LeftIcon size={30}/>
                  <RightIcon size={30} style={{marginTop: "50px"}}/>
                </> : <>
                  <RightIcon size={30}/>
                  <LeftIcon size={30} style={{marginTop: "50px"}}/>
                </>}
              </Col>
              <Col sm="auto" className="d-none d-md-block">
                {bypass ? <>
                  <RoomContent
                    layoutItem={{}}
                    room={room?.exhaust}
                    background={{}}
                  />
                  <RoomContent
                    layoutItem={{}}
                    room={room?.freshIn}
                    background={{}}
                  />
                </> : <>
                  <RoomContent
                    layoutItem={{}}
                    room={room?.freshIn}
                    background={{}}
                  />
                  <RoomContent
                    layoutItem={{}}
                    room={room?.exhaust}
                    background={{}}
                  />
                </>}
              </Col>
              <Col>
                <ModeIcon
                  size={85}
                  className="room-ventilation-mode-icon"
                  onClick={() => {
                    setTempChangeRoom(room.bypass);
                    setShowTempChange(true);
                  }}
                />
                <div
                  className="room-ventilation-speed"
                  onClick={() => {
                    setTempChangeRoom(room);
                    setShowTempChange(true);
                  }}
                >
                  {room?.power_output}
                </div>
              </Col>
              <Col xs="auto" sm="auto" className="d-block">
                <RoomContent
                  layoutItem={{}}
                  room={room?.houseIn}
                  background={{}}
                />
                <RoomContent
                  layoutItem={{}}
                  room={room?.houseOut}
                  background={{}}
                />
              </Col>
              <Col className="flex-column justify-content-start d-none d-lg-flex" sm="auto">
                <LeftIcon size={30}/>
                <HouseIcon size={50} className="ms-4"/>
                <RightIcon size={30}/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Ventilation;
