import React from 'react';
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';

import './DashboardLayout.css';
import Room from './Room';
import {MQTTContext} from '../util/MQTTDataProvider';


class DashboardLayout extends React.Component {

  static contextType = MQTTContext;

  componentDidMount() {
    this.grid = GridStack.init({
      cellHeight: 90,
      marginTop: 5,
      marginBottom: 5,
  		float: true,
      minRow: 6,
      staticGrid: true,
      oneColumnModeDomSort: true,
      disableOneColumnMode: true,
    }, `#layout-${this.props.id}`);

    this.resizeGrid();
    window.addEventListener('resize', this.resizeGrid.bind(this));
  }

  resizeGrid() {
    // workaround - the DOM sorting didn't work until resize event
    if (document.body.clientWidth < 768) {
      this.grid.column(1);
    }
    else {
      this.grid.column(12);
    }
  }

  render() {
    const rooms = this.context.rooms || {};
    return (
      <div className="grid-stack" id={`layout-${this.props.id}`}>
        {this.props.items.map((item, i) =>
          <Room
            key={i}
            layoutItem={item}
            room={rooms[item.roomId]}
          />
        )}
      </div>
    );
  }
}

export default DashboardLayout;
