import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import api from '../util/API';


export default function SetProfileModal({show, onClose}) {
  const [profiles, setProfiles] = useState([]);


  useEffect(() => {
    if (show) { // run every time the dialog opens
      (async () => {
        const data = await api.get(`profile`);
        setProfiles(data);
      })();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Activate Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {profiles.map((p, i) => (
          <Button key={i}
            size="lg"
            variant={p.active ? "success" : "secondary"}
            className="d-block mb-3 w-100"
            active={false}
            onClick={async (evt) => {
              await api.post(`profile/set/${i}`);
              const data = await api.get(`profile`);
              setProfiles(data);
              evt.target.blur();
            }}
          >
            {p.name}
          </Button>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

  );
}
