import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {
  FaPlusSquare as Plus,
  FaTrashAlt as Trash,
} from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';

import './ProfileConfig.css';
import api from '../util/API';

function RoomDetail({readOnly, start, temperature, onTrash, onAdd, minTime, maxTime, setStart, setTemperature, tweaks}) {
  const maxTemp = tweaks?.targetMax || tweaks?.rangeMax || 25;
  const step = tweaks?.step || 0.5;
  const unit = tweaks?.unit || "°C";

  return (
    <>
      <div className="d-inline-block me-4 border border-secondary rounded p-1">
        <Form.Control type="time"
          className="d-inline-block mx-1"
          step="900"
          style={{'width': '5em'}}
          value={start}
          min={minTime}
          max={maxTime}
          readOnly={readOnly}
          onChange={evt => {
            const val = evt.target.value;
            setStart(val);
          }}
        />
        <span style={{whiteSpace: 'nowrap'}} className="d-inline">
          <Form.Control type="number" min="0" max={maxTemp}
            className="d-inline-block mx-1"
            step={step}
            style={{'width': '6em'}}
            value={temperature}
            onChange={evt => {
              if (evt.target.value === '') {
                setTemperature(null);
              } else {
                setTemperature(Math.min(Math.max(evt.target.value, 0), maxTemp));
              }
            }}
          />{unit}
        </span>

        <Trash size={16}
          className="cursorPointer ms-3 me-1 text-danger"
          onClick={onTrash}
        />
      </div>

      <Plus size={32}
        onClick={onAdd}
        className="cursorPointer me-4 text-primary"
      />
    </>
  );
}

function ProfileConfig(props) {
  const [name, setName] = useState("");
  const [group, setGroup] = useState("");
  const [rooms, setRooms] = useState([]);
  const [roomDetails, setRoomDetails] = useState({});
  const [saving, setSaving] = useState(false);
  const {profileId} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async()=>{
      const res = await api.get(`profile/config/${profileId}`);
      setName(res.profile.name);
      setGroup(res.profile.group || "");
      setRooms(res.profile.rooms);
      setRoomDetails(res.rooms);
    })();
  }, [profileId]);

  const updateRooms = () => setRooms({...rooms});

  const saveProfile = async () => {
    try {
      setSaving(true);
      await api.post(`profile/config/${profileId}`, {
        name: name,
        group: group,
        rooms: rooms,
      });
      navigate('/');
    } finally {
      setSaving(false);
    }
  };

  function getRoomEntry(roomId) {
    var room = rooms[roomId];
    if (!room) {
      room = rooms[roomId] = {details: [{start: "00:00"}]};
    }
    return room;
  }
  return (
    <>
      <Form className="bg-light">
        <Row className="mb-3">
          <Col className="d-flex justify-content-left mt-3 mb-2">
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text"
                size="lg"
                value={name}
                style={{'width': '16em'}}
                onChange={evt => {
                  const val = evt.target.value;
                  setName(val);
                }}
              />
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-left mt-3 mb-2">
            <Form.Group>
              <Form.Label>Group</Form.Label>
              <Form.Control type="text"
                size="lg"
                value={group}
                style={{'width': '16em'}}
                onChange={evt => {
                  const val = evt.target.value;
                  setGroup(val);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        {Object.entries(roomDetails).map(([roomId, {name, tweaks}]) => {
          const room = getRoomEntry(roomId);
          return (
            <div key={roomId}>
              <Row className="">
                <Col>
                  <h4>{name}</h4>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col>
                  {(room.details.length === 0) &&
                    <Plus size={32}
                      onClick={() => {
                        room.details.push({start: "00:00"});
                        updateRooms();
                      }}
                      className="cursorPointer me-4 text-primary"
                    />
                  }
                  {room.details.map((detail, i) => (
                    <RoomDetail key={i}
                      {...detail}
                      tweaks={tweaks}
                      readOnly={i===0}
                      minTime={room.details[i-1]?.start || "00:00"}
                      maxTime={room.details[i+1]?.start || "23:59"}
                      onAdd={() => {
                        room.details.splice(i+1, 0, {...detail});
                        updateRooms();
                      }}
                      onTrash={() => {
                        room.details.splice(i, 1);
                        updateRooms();
                      }}
                      setStart={(val) => {
                        detail.start = val;
                        updateRooms();
                      }}
                      setTemperature={(val) => {
                        detail.temperature = val;
                        updateRooms();
                      }}
                    />
                  ))}
                  {(room.details.length > 0) &&
                    <Form.Control type="time"
                      className="d-inline-block mx-1"
                      style={{'width': '5em'}}
                      value="23:59"
                      readOnly
                    />
                  }
                </Col>
              </Row>
            </div>
          );
        })}

        <Row>
          <Col className="text-center">
            {saving ?
              <Spinner animation="border" variant="primary"/>
            :
              <Button className="mb-3" size="lg" variant="success" onClick={saveProfile}>Save Profile</Button>
            }
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ProfileConfig;
