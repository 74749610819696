import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import { FaPlusCircle as Plus, FaMinusCircle as Minus } from 'react-icons/fa';

import Chart from './Chart';
import './DayStatistics.css';


function formatD(momentDT) {
  return momentDT.format().substring(0, 10);
}

function shiftDate(startTime, endTime) {
  // shift by the amount of days that are in the interval (week, month, ...)
  var unit = 'day';
  var amount = moment(endTime).diff(moment(startTime), 'days');
  if (amount >= 28) {
    // shift by a month if looking at a whole month (or more)
    amount = 1;
    unit = 'month';
  }
  return {amount, unit};
}

function DayStatistics(props) {
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment().add(1, 'day'));

  // formatted to string
  const startTimeS = formatD(startTime);
  const endTimeS = formatD(endTime);

  return (
    <>
      <Form className="bg-light">
        <Row className="mb-3">
          <Col>
            <Minus color="green" size={64}
              className="cursorPointer d-inline mx-2"
              onClick={(evt) => {
                evt.stopPropagation();
                const {amount, unit} = shiftDate(startTime, endTime);
                setStartTime(moment(startTime.add(-amount, unit)));
                setEndTime(moment(endTime.add(-amount, unit)));
              }}
            />
            <DateRangePicker
              clearIcon={null}
              required={true}
              showNeighboringMonth={true}
              onChange={dr => {
                setStartTime(moment(dr[0]));
                setEndTime(moment(dr[1]));
              }}
              value={[startTime.toDate(), endTime.toDate()]}
            />
            <Plus color="green" size={64}
              className="cursorPointer d-inline mx-2"
              onClick={(evt) => {
                evt.stopPropagation();
                const {amount, unit} = shiftDate(startTime, endTime);
                setStartTime(moment(startTime.add(amount, unit)));
                setEndTime(moment(endTime.add(amount, unit)));
              }}
            />
          </Col>
        </Row>

      </Form>

      {startTimeS && endTimeS && <>
        <Chart
          storageKey="TEMPERATURE_ROOMS"
          source={`chart/day/temperature?type=rooms&startTime=${startTimeS}&endTime=${endTimeS}`}
          type="line"
          yFormatter={function (value) {
            return value != null ? `${value.toFixed(1)} °C` : "?";
          }}
          tooltipXFormat="dd MMM H:mm"
          title={() => "Temperature (Rooms)"}
        />

        <Chart
          storageKey="TEMPERATURE_SYSTEM"
          source={`chart/day/temperature?type=system&startTime=${startTimeS}&endTime=${endTimeS}`}
          type="line"
          yFormatter={function (value) {
            return value != null ? `${value.toFixed(1)} °C` : "?";
          }}
          tooltipXFormat="dd MMM H:mm"
          title={() => "Temperature (System)"}
        />

        <Chart
          storageKey="POWEROUTPUT"
          source={`chart/day/poweroutput?type=rooms&startTime=${startTimeS}&endTime=${endTimeS}`}
          type="bar" stacked
          yFormatter={function (value) {
            value = value || 0;
            const v = Number.isSafeInteger(value) ? parseInt(value) : value.toFixed(1);
            return `${v}`;
          }}
          tooltipXFormat="dd MMM H:mm"
          title={(x) => `Heating effort: ${(x || 0).toFixed(1)} kWh(kinda)`}
        />

        <Chart
          storageKey="POWERUSAGE"
          source={`chart/day/powerusage?startTime=${startTimeS}&endTime=${endTimeS}`}
          type="bar" stacked
          yFormatter={function (value) {
            value = value || 0;
            const v = Number.isSafeInteger(value) ? parseInt(value) : value.toFixed(1);
            return `${v} kWh`;
          }}
          tooltipXFormat="dd MMM H:mm"
          title={(x) => `Power used: ${(x || 0).toFixed(1)} kWh`}
        />

        <Chart
          storageKey="POWERCOST"
          source={`chart/day/powercost?startTime=${startTimeS}&endTime=${endTimeS}`}
          type="bar" stacked
          yFormatter={function (value) {
            value = value || 0;
            return Number.isSafeInteger(value) ?
              `$${parseInt(value)}` : `$${value.toFixed(2)}`;
          }}
          tooltipXFormat="dd MMM H:mm"
          title={(x) => `Power cost: $${(x || 0).toFixed(2)}`}
        />

        <Chart
          storageKey="HUMIDITY"
          source={`chart/day/humidity?startTime=${startTimeS}&endTime=${endTimeS}`}
          type="line"
          yFormatter={function (value) {
            value = value || 0;
            return `${Math.round(value)}%`;
          }}
          tooltipXFormat="dd MMM H:mm"
          title={() => "Humidity"}
        />
      </>}
    </>
  );
}

export default DayStatistics;
